import styled from "styled-components";
import { Select as SelectMui, Box } from "@material-ui/core";

const Content = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const Form = styled(Box)`
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Preview = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: calc(50% - 20px);
  @media (max-width: 768px) {
    width: 100%;
    margin: 25px auto;
  }
`;

const Video = styled.video`
  border: 1px solid #aaa;
  object-fit: cover;
  border-radius: 8px;
  margin: 20px auto;
  max-width: 198px;
  height: 150px;
`;

const FormField = styled(Box)`
  display: flex;
  flex-direction: column;
`;
const Select = styled(SelectMui)`
  width: 100%;
  margin: 10px 0px;
  box-sizing: border-box;
  padding-right: 0px;
  @media (max-width: 768px) {
    margin: 10px 0px;
    flex-direction: column;
    min-width: auto;
    width: 100%;
  }
`;

export { Content, Form, Preview, FormField, Select, Video };
