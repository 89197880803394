import styled from "styled-components";
import logo from "../../assets/logo-pb.png";
import { Typography } from "@material-ui/core";

const MainVideo = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #929292;
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200px;
  object-fit: cover;
`;

const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #929292;
  position: relative;
  overflow: hidden;
  background-image: url(${logo});
  background-size: cover;
`;

const ContainerBottom = styled.div`
  display: flex;
  width: 100vw;
  position: absolute;
  bottom: ${(props) => (props.showTabbar ? "0px" : "-88px")};
  padding-top: 88px;
  transition: 0.2s ease-in-out;
  &:hover {
    bottom: 0px;
  }
`;

const ContentBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #fff;
  height: 88px;
  box-shadow: ${(props) => props.theme.shadows[5]};
`;

const FrameSecondary = styled.video`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 320px;
  height: 180px;
  max-width: 100%;
  background-color: #929292;
  background-image: url(${logo});
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.2s ease-in-out;
  background-size: 100px;
  box-shadow: ${(props) => props.theme.shadows[5]};
  object-fit: cover;
  @media (max-width: 768px) {
    width: 50px;
    height: 75px;
    background-size: contain;
  }
`;

const AttendeeNameContainer = styled.div`
  border-radius: 10px;
  background-color: #fffb;
  padding: 10px 20px;
  position: absolute;
  left: 10px;
  bottom: ${(props) => (props.showTabbar ? "98px" : "10px")};
  transition: 0.2s ease-in-out;
`;

const AttendeeName = styled(Typography).attrs({ variant: "h6" })``;

export {
  FrameSecondary,
  MainVideo,
  ContentBottom,
  ContainerBottom,
  Main,
  AttendeeNameContainer,
  AttendeeName,
};
