import React from "react";

import styled from "styled-components";

const ButtonStyled = styled.div`
  display: flex;
`;

const Button = (props) => {
  return <ButtonStyled>Button works!</ButtonStyled>;
};

export default Button;
