import React, { useContext } from "react";

import styled, { ThemeContext, css } from "styled-components";

const RoundButtonStyled = styled.div`
  background-color: ${(props) =>
    props.bgColor || props.theme.palette.primary.main};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin: 0 24px;
  cursor: pointer;
  border: solid 1px
    ${(props) => props.bgColor || props.theme.palette.primary.main};
  color: ${(props) => props.theme.palette.primary.contrastText};
  ${(props) =>
    props.outline &&
    css`
      color: ${(props) => props.theme.palette.primary.light};
      border: solid 1px ${props.theme.palette.primary.light};
      background-color: transparent;
    `}
`;

const RoundButton = (props) => {
  const { outline, onClick, bgColor } = props;
  const themeContext = useContext(ThemeContext);
  return (
    <RoundButtonStyled bgColor={bgColor} outline={outline} onClick={onClick}>
      {React.cloneElement(props.children, {
        color: themeContext.palette.primary.constrastColor,
        fontSize: "large",
      })}
    </RoundButtonStyled>
  );
};

export default RoundButton;
