import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

//ICONS
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import PhoneDisabledIcon from "@material-ui/icons/PhoneDisabled";
import { RoundButton } from "../../components";
import * as S from "./styles";

function Meeting(props, ref) {
  const { stopMeeting, toggleCam, toggleMic, attendeeName } = props;
  const [deviceStatus, setDeviceStatus] = useState({
    camera: false,
    microphone: false,
  });
  const [showTabbar, setShowTabbar] = useState(false);
  const [lockShowTabbar, setLockShowTabbar] = useState(false);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);

  const toggleDeviceStatus = (device) => {
    setDeviceStatus((prev) => ({ ...prev, [device]: !prev[device] }));
  };

  useEffect(() => {
    let debounceTimer = null;

    const showControls = () => {
      if (!showTabbar) setShowTabbar(true);
      clearInterval(debounceTimer);
      debounceTimer = setTimeout(() => {
        setShowTabbar(false);
      }, 2000);
    };
    remoteVideoRef.current.addEventListener("mousemove", showControls);
  }, [showTabbar]);

  useImperativeHandle(ref, () => ({ localVideoRef, remoteVideoRef }));

  return (
    <S.Main>
      <S.MainVideo ref={remoteVideoRef} />
      <S.FrameSecondary ref={localVideoRef} />
      {attendeeName && (
        <S.AttendeeNameContainer showTabbar={showTabbar || lockShowTabbar}>
          <S.AttendeeName>{attendeeName}</S.AttendeeName>
        </S.AttendeeNameContainer>
      )}
      <S.ContainerBottom
        showTabbar={showTabbar}
        onMouseEnter={() => setLockShowTabbar(true)}
        onMouseLeave={() => setLockShowTabbar(false)}
      >
        <S.ContentBottom>
          <RoundButton
            outline={deviceStatus.microphone}
            onClick={() => {
              toggleMic();
              toggleDeviceStatus("microphone");
            }}
          >
            {deviceStatus.microphone ? <MicOffIcon /> : <MicIcon />}
          </RoundButton>
          <RoundButton
            outline={deviceStatus.camera}
            onClick={() => {
              toggleCam();
              toggleDeviceStatus("camera");
            }}
          >
            {deviceStatus.camera ? <VideocamOffIcon /> : <VideocamIcon />}
          </RoundButton>

          <RoundButton onClick={stopMeeting} bgColor={"#B20F0F"}>
            <PhoneDisabledIcon />
          </RoundButton>
        </S.ContentBottom>
      </S.ContainerBottom>
    </S.Main>
  );
}

export default forwardRef(Meeting);
