import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { aws_regions } from "../../domain/Constants";
import { TextField } from "../../components";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

function Authenticate({ state, setState, onJoin, onCreate, loading }) {
  const regions = aws_regions;
  const [progressVariant, setProgressVariant] = useState("determinate");

  useEffect(() => {
    setProgressVariant(loading ? "indeterminate" : "determinate");
  }, [loading]);

  const on_change_region = (e) => {
    setState({ region: e.target.value });
  };

  function handle_continue() {
    setProgressVariant("indeterminate");
    if (state.meeting_name.trim().length === 0) {
      onCreate();
    } else {
      onJoin();
    }
  }

  function handle_input_change(field_name, value) {
    setState({ [field_name]: value });
  }

  const handle_change_create = (e) => {
    setState({ create: e.target.checked });
  };

  return (
    <Container maxWidth="xs">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h2" align="center">
            Entrar na consulta
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={state.create}
                onChange={handle_change_create}
                name="create"
                color="primary"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            }
            label={
              state.create
                ? "Criar Meeting"
                : "Juntar-se à um meeting existente"
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={state.create}
            id="inputMeeting"
            label="ID da consulta"
            value={state.meeting_name}
            onChange={(e) =>
              handle_input_change("meeting_name", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="inputName"
            label="Seu nome"
            value={state.attendee_name}
            onChange={(e) =>
              handle_input_change("attendee_name", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12}>
          {/*<label htmlFor="inputRegion">Media Region </label>*/}
          <Select
            id="inputRegion"
            value={state.region}
            onChange={on_change_region}
          >
            {regions.map((v, i) => (
              <MenuItem key={i} value={v.value}>
                {v.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handle_continue}
            disabled={loading || !state.attendee_name}
          >
            Continue
          </Button>
        </Grid>

        <Container>
          <LinearProgress variant={progressVariant} value={0} />
        </Container>
      </Grid>
    </Container>
  );
}

export default Authenticate;
