import React, { useEffect, useRef } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import { Button, makeStyles } from "@material-ui/core";
import { Line } from "rc-progress";
import TestSound from "../../domain/TestSound";
import * as S from "./styles";

const useStyles = makeStyles((theme) => ({
  audio: {
    display: "none",
  },
}));

export default function PreviewAndTest({
  onVideoElementReady,
  audioInPercent,
  onAudioElementReady,
  audioOutputDevice,
}) {
  const classes = useStyles();
  const videoEl = useRef(null);
  const audioEl = useRef(null);

  useEffect(() => {
    onVideoElementReady(videoEl);
  }, [onVideoElementReady]);

  useEffect(() => {
    onAudioElementReady(audioEl);
  }, [onAudioElementReady]);

  function handle_test_sound() {
    new TestSound(audioOutputDevice.deviceId);
  }

  return (
    <>
      <InputLabel>Preview</InputLabel>
      <Line percent={audioInPercent} />
      <audio ref={audioEl} id="audio-preview" className={classes.audio} />
      <S.Video ref={videoEl} id="video-preview" />
      <Button
        color="primary"
        fullWidth={true}
        variant="outlined"
        onClick={handle_test_sound}
      >
        Test
      </Button>
    </>
  );
}
