import React from "react";
import { MenuItem } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import * as S from "./styles";

export const DeviceForm = ({ state, setState, devicesList }) => {
  const { audioInputList, videoInputList, audioOutputList } = devicesList;

  const videoQualityList = [
    { value: "360", label: "360p (nHD) @ 15 fps (600 Kbps max)" },
    {
      value: "540",
      label: "540p (qHD) @ 15 fps (1.4 Mbps max)",
    },
    {
      value: 720,
      label: "720p (HD) @ 15 fps (1.4 Mbps max)",
    },
  ];

  const on_select_change = (select_name, value) => {
    setState({ ...state, [select_name]: value });
  };

  const on_video_input_change = async (e) => {
    setState({ ...state, video_input: e.target.value });
  };

  const on_audio_input_change = async (e) => {
    setState({ ...state, audio_input: e.target.value });
  };

  const on_audio_output_change = (e) => {
    setState({ ...state, audio_output: e.target.value });
  };

  return (
    <>
      <S.FormField>
        <InputLabel id="audio-input">Microfone</InputLabel>
        <S.Select
          id="audio-input"
          value={state.audio_input || ""}
          onChange={on_audio_input_change}
        >
          {audioInputList.map((v, i) => (
            <MenuItem key={i} value={v}>
              {v.label}
            </MenuItem>
          ))}
        </S.Select>
      </S.FormField>
      <S.FormField>
        <InputLabel id="video-input">Câmera</InputLabel>
        <S.Select
          id="video-input"
          value={state.video_input || ""}
          onChange={on_video_input_change}
        >
          {videoInputList.map((v, i) => (
            <MenuItem key={i} value={v}>
              {v.label}
            </MenuItem>
          ))}
        </S.Select>
        <S.Select
          id="video-input-quality"
          onChange={(e) => on_select_change("video_quality", e.target.value)}
          value={state.video_quality || ""}
        >
          {videoQualityList.map((v, i) => (
            <MenuItem key={i} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </S.Select>
        <S.FormField>
          <InputLabel id="audio-output">Speaker</InputLabel>
          <S.Select
            id="audio-output"
            onChange={on_audio_output_change}
            value={state.audio_output || ""}
          >
            {audioOutputList.map((v, i) => {
              return (
                <MenuItem key={i} value={v}>
                  {v.label}
                </MenuItem>
              );
            })}
          </S.Select>
        </S.FormField>
      </S.FormField>
    </>
  );
};
