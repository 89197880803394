import React, { useEffect, useRef, useState } from "react";
import { Container } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import PreviewAndTest from "./PreviewAndTest";
import Typography from "@material-ui/core/Typography";
import { DeviceForm } from "./DeviceForm";
import JoinButton from "./JoinButton";
import * as S from "./styles";

const useAnimationFrame = (callback) => {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = React.useRef();
  const previousTimeRef = React.useRef();

  const animate = (time) => {
    if (previousTimeRef.current !== undefined) {
      callback();
    }
    previousTimeRef.current = time;
    requestRef.current = requestAnimationFrame(animate);
  };

  React.useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(requestRef.current);
  }); // Make sure the effect runs only once
};

export default function Devices({
  session,
  meeting,
  attendeeName,
  enterMeeting,
  getDataVideQuality,
}) {
  const [devicesList, setDevicesList] = useState({
    videoInputList: [],
    audioInputList: [],
    audioOutputList: [],
  });
  const [deviceConfig, setDeviceConfig] = useState({
    audio_input: "",
    video_input: "",
    video_quality: "",
    audio_output: "",
  });

  const [videoElement, setVideoElement] = useState(null);
  const [audioElement, setAudioElement] = useState(null);
  const [audioInputPercent, setAudioInputPercent] = useState(null);
  const analyserRef = useRef(null);

  useEffect(() => {
    const audioVideo = session.session.audioVideo;
    if (!audioVideo) return;
    const promiseAudioInput = audioVideo.listAudioInputDevices();
    const promiseAudioOutput = audioVideo.listAudioOutputDevices();
    const promiseVideoInput = audioVideo.listVideoInputDevices();
    console.log("GET LIST DEVICES");

    Promise.all([
      promiseAudioInput,
      promiseAudioOutput,
      promiseVideoInput,
    ]).then(
      ([
        listAudioInputDevices,
        listAudioOutputDevices,
        listVideoInputDevices,
      ]) => {
        console.log(
          listAudioInputDevices,
          listAudioOutputDevices,
          listVideoInputDevices
        );
        setDevicesList({
          audioInputList: listAudioInputDevices,
          audioOutputList: listAudioOutputDevices,
          videoInputList: listVideoInputDevices,
        });
      }
    );
  }, [session.session.audioVideo]);

  useEffect(() => {
    const {
      audioInputList = [],
      audioOutputList = [],
      videoInputList = [],
    } = devicesList;
    setDeviceConfig({
      video_quality: "540",
      video_input: videoInputList[0],
      audio_input: audioInputList[0],
      audio_output: audioOutputList[0],
    });
  }, [devicesList]);

  useEffect(() => {
    if (!session.session.audioVideo) return;
    function onChooseVideoInputDevice(device) {
      session.session.audioVideo
        .chooseVideoInputDevice(device)
        .then((devicePermission) => {
          if (videoElement) {
            session.session.audioVideo.startVideoPreviewForVideoInput(
              videoElement.current
            );
          }
        });
    }

    function onChooseAudioInputDevice(device) {
      session.session.audioVideo
        .chooseAudioInputDevice(device)
        .then((devicePermission) => {
          analyserRef.current = session.session.audioVideo.createAnalyserNodeForAudioInput();
        })
        .catch((err) => {
          console.error("Erro em onChooseAudioInputDevice: ", err);
        });
    }

    function onChooseAudioOutputDevice(device) {
      session.session.audioVideo
        .chooseAudioOutputDevice(device.deviceId)
        .then((devicePermission) => {
          if (audioElement) {
            session.session.audioVideo.bindAudioElement(audioElement.current);
          }
        })
        .catch((err) => {
          console.error("Erro em onChooseAudioInputDevice: ", err);
        });
    }

    function onChooseVideoQuality(device) {
      session.session.audioVideo.chooseVideoInputQuality(...device);
    }

    const {
      audio_input,
      audio_output,
      video_input,
      video_quality,
    } = deviceConfig;

    console.log("config :: ", deviceConfig);
    audio_input && onChooseAudioInputDevice(audio_input);
    audio_output && onChooseAudioOutputDevice(audio_output);
    video_input && onChooseVideoInputDevice(video_input);
    video_quality && onChooseVideoQuality(getDataVideQuality(video_quality));
  }, [
    deviceConfig,
    audioElement,
    session.session.audioVideo,
    videoElement,
    getDataVideQuality,
  ]);

  function handleJoinMeeting() {
    enterMeeting(deviceConfig);
  }

  useAnimationFrame(() => {
    const analyserNode = analyserRef.current;
    if (!analyserNode || !analyserNode.getFloatTimeDomainData) {
      return;
    }

    const data = new Float32Array(analyserNode.fftSize);
    analyserNode.getFloatTimeDomainData(data);
    const lowest = 0.01;
    let max = lowest;
    for (const f of data) {
      max = Math.max(max, Math.abs(f));
    }
    const normalized = (Math.log(lowest) - Math.log(max)) / Math.log(lowest);
    const percent = Math.min(Math.max(normalized * 100, 0), 100);
    setAudioInputPercent(percent);
  });

  function onVideoElementReady(videoElement) {
    setVideoElement(videoElement);
  }

  function onAudioElementReady(audioElement) {
    setAudioElement(audioElement);
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" align="center" gutterBottom={true}>
        Selecionar dispositivos
      </Typography>
      <S.Content mt={4}>
        <S.Form>
          <DeviceForm
            state={deviceConfig}
            setState={setDeviceConfig}
            devicesList={devicesList}
          />
        </S.Form>
        <S.Preview>
          <PreviewAndTest
            onVideoElementReady={onVideoElementReady}
            onAudioElementReady={onAudioElementReady}
            audioInPercent={audioInputPercent}
            audioOutputDevice={deviceConfig.audio_output}
          />
        </S.Preview>
      </S.Content>
      <Box>
        <JoinButton
          meetingId={meeting.Meeting.MeetingId}
          attendeeName={attendeeName}
          joinMeeting={handleJoinMeeting}
        />
      </Box>
      <div id="progress-join" className="w-100 progress progress-hidden">
        <div
          className="w-100 progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow={100}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
    </Container>
  );
}
