export const Modais = {
  authenticate: "authenticate",
  devices: "devices",
  meeting: "meeting",
  none: "none",
};

export const Actions = {
  setAttendeeName: "setAttendeeName",
  setModal: "setModal",
  setLocalTileId: "setLocalTileId",
  goDevices: "goDevices",
  setLoading: "setLoading",
  setAuthData: "setAuthData",
  setMeeting: "setMeeting",
  setSession: "setSession",
  setVideoElement: "setVideoElement",
  removeSession: "removeSession",
};

export const initialState = {
  modal: Modais.authenticate,
  loading: false,
  authData: {
    region: "sa-east-1",
    meeting_name: "",
    attendee_name: "",
    create: true,
  },
  meeting: {
    meeting_id: "",
    Meeting: {},
    Attendee: {},
  },
  session: {
    logger: null,
    session: {},
  },
  VideoElement: null,
  localTileId: null,
  devicesConfig: {
    audio_input: "",
    video_input: "",
    video_quality: "540",
    audio_output: "",
  },
  attendeeName: "",
};

export const reducer = (state = initialState, action) => {
  function merge(oldState, partialState) {
    return Object.keys(partialState).reduce(
      (prev, cur) => ({
        ...prev,
        [cur]: partialState[cur],
      }),
      oldState
    );
  }

  switch (action.type) {
    case Actions.setAttendeeName:
      return { ...state, attendeeName: action.payload };
    case Actions.setModal:
      return { ...state, modal: action.payload };
    case Actions.setLoading:
      return { ...state, loading: action.payload };
    case Actions.setAuthData:
      return { ...state, authData: merge(state.authData, action.payload) };
    case Actions.setMeeting:
      return { ...state, meeting: merge(state.meeting, action.payload) };
    case Actions.setSession:
      return {
        ...state,
        session: merge(state.session, action.payload),
        modal: Modais.devices,
      };
    case Actions.removeSession:
      return initialState;
    case Actions.setVideoElement:
      return { ...state, VideoElement: action.payload };
    default:
      return state;
  }
};

export class Dispatch {
  constructor(dispatch_fn) {
    this.dispatch_fn = dispatch_fn;
  }

  setAttendeeName(attendeeName) {
    this.dispatch_fn({ type: Actions.setAttendeeName, payload: attendeeName });
  }

  setModal(modal) {
    this.dispatch_fn({ type: Actions.setModal, payload: modal });
  }

  setLocalTileId(tileId) {
    this.dispatch_fn({ type: Actions.setLocalTileId, payload: tileId });
  }

  setLoading(param) {
    this.dispatch_fn({ type: Actions.setLoading, payload: param });
  }

  setAuthData(param) {
    this.dispatch_fn({ type: Actions.setAuthData, payload: param });
  }

  setMeeting(param) {
    this.dispatch_fn({ type: Actions.setMeeting, payload: param });
  }

  setSession(session) {
    this.dispatch_fn({ type: Actions.setSession, payload: session });
  }

  removeSession(param) {
    this.dispatch_fn({ type: Actions.removeSession, payload: param });
  }

  setVideoElement(param) {
    this.dispatch_fn({ type: Actions.setVideoElement, payload: param });
  }
}
