import React from "react";
import { TextField as TextFieldMD } from "@material-ui/core";
import styled from "styled-components";

const TextFieldStyled = styled(TextFieldMD)`
  width: 100%;
`;

export default function TextField(props) {
  return <TextFieldStyled {...props} />;
}
