import Settings from "../config/settings";
import Axios from "axios";

function createMeetingUrl() {
  return `${Settings.API_URL}/meeting`;
}

function joinMeetingUrl() {
  return `${Settings.API_URL}/join`;
}

function getAttendeeNameUrl() {
  return `${Settings.API_URL}/attendee`;
}

export function CreateMeeting(media_region) {
  const options = {
    headers: {
      "content-type": "application/json",
    },
  };
  const payload = {
    media_region: media_region,
  };

  return new Promise((resolve, reject) => {
    Axios.post(createMeetingUrl(), payload, options)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function JoinMeeting(meeting_id, attendee_name) {
  const payload = {
    meeting_id: meeting_id,
    name: attendee_name,
  };

  return new Promise((resolve, reject) => {
    Axios.post(joinMeetingUrl(), payload)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function GetAttendeeName(attendee_id) {
  console.log(`GetAttendeeNameUrl`, attendee_id);

  return new Promise((resolve, reject) => {
    Axios.get(`${getAttendeeNameUrl()}/${attendee_id}`)
      .then((res) => {
        console.log(`GetAttendeeNameUrl`, res.data.data);
        resolve(res.data.data.AttendeeInfo.Name);
      })
      .catch((error) => {
        console.log("ERROR GET ATTENDEE NAME :: ", error);
        reject(error);
      });
  });
}
