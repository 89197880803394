import React from "react";

import styled from "styled-components";
import { Paper, Modal as ModalMui } from "@material-ui/core";

const ModalStyled = styled(ModalMui)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaperStyled = styled(Paper)`
  padding: 50px 20px;
  margin: 20px;
  border-radius: 10px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  box-sizing: border-box;
`;

const Modal = (props) => {
  const { onClose, open } = props;
  return (
    <ModalStyled
      open={open}
      onClose={onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <PaperStyled elevation={10}>{props.children}</PaperStyled>
    </ModalStyled>
  );
};

export default Modal;
