import { createMuiTheme } from "@material-ui/core";

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: "#50D8AD",
      light: "#bcbcbc",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  },
  typography: {
    fontFamily: "OpenSans",
    h4: {
      color: "#333",
    },
  },
  overrides: {
    MuiInput: {
      input: {
        boxSizing: "border-box",
        height: "32px",
      },
    },
  },
});

export default Theme;
