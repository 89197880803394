import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export default function JoinButton({ meetingId, attendeeName, joinMeeting }) {
  return (
    <>
      <Box mt={2}>
        <Button
          id="joinButton"
          color="primary"
          fullWidth={true}
          variant="outlined"
          onClick={joinMeeting}
        >
          Entrar
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant="body1" component="p">
          Ready to join meeting <b>{meetingId}</b> as <b>{attendeeName}</b>.
        </Typography>
      </Box>
    </>
  );
}
