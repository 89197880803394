import React, { useEffect, useReducer, useState } from "react";

import Authenticate from "./pages/Authenticate";
import Devices from "./pages/Devices";
import {
  CreateMeeting,
  JoinMeeting,
  GetAttendeeName,
} from "./services/meeting_service";
import { ThemeProvider } from "styled-components";
import Theme from "./styles/themes/Theme";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core";
import { Modal } from "./components/";
import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration,
} from "amazon-chime-sdk-js";
import { Dispatch, Modais, reducer, initialState } from "./domain/App";
import Meeting from "./pages/Meeting";
import { useRef } from "react";

const App = () => {
  const meetingRef = useRef(null);
  const [devicesConfig, setDevicesConfig] = useState({});

  const [state, dispatch_fn] = useReducer(reducer, initialState);

  const { modal, loading, session, meeting, localTileId } = state;

  let dispatch = new Dispatch(dispatch_fn);

  function getDataVideQuality(key) {
    return {
      360: [640, 360, 15, 600],
      540: [960, 540, 15, 1400],
      720: [1280, 720, 15, 1400],
    }[key];
  }

  function getLocalVideoRef() {
    return (
      meetingRef &&
      meetingRef.current &&
      meetingRef.current.localVideoRef &&
      meetingRef.current.localVideoRef.current
    );
  }

  function getRemoteVideoRef() {
    return (
      meetingRef &&
      meetingRef.current &&
      meetingRef.current.remoteVideoRef &&
      meetingRef.current.remoteVideoRef.current
    );
  }

  //////////////////////////
  // HANDLERS
  function handleSetMeeting(_meeting) {
    dispatch.setMeeting({
      meeting_id: _meeting.JoinInfo.Meeting.MeetingId,
      Meeting: _meeting.JoinInfo.Meeting,
      Attendee: _meeting.JoinInfo.Attendee,
    });
    dispatch.setAuthData({
      meeting_name: _meeting.JoinInfo.Meeting.MeetingId,
    });
  }

  async function onCreate() {
    dispatch.setLoading(true);
    try {
      const dataCreate = await CreateMeeting(state.authData.region);
      const dataJoin = await JoinMeeting(
        dataCreate.JoinInfo.Meeting.MeetingId,
        state.authData.attendee_name
      );
      handleSetMeeting(dataJoin);
    } catch (error) {
      console.log("Erro em CreateMeeting: ", error);
    }
    dispatch.setLoading(false);
  }

  function onJoin() {
    dispatch.setLoading(true);
    const { meeting_name, attendee_name } = state.authData;
    JoinMeeting(meeting_name, attendee_name)
      .then((res) => {
        handleSetMeeting(res);
        dispatch.setLoading(false);
      })
      .catch((err) => {
        dispatch.setLoading(false);
        console.log("Erro em JoinMeeting: ", err);
      });
  }

  function enterMeeting(devicesConfig) {
    setDevicesConfig(devicesConfig);
    const _session = session.session;
    if (!_session) return;
    _session.audioVideo.addObserver(observer);

    if (devicesConfig.video_input) {
      _session.audioVideo
        .chooseVideoInputDevice(devicesConfig.video_input)
        .then((devicePermission) => {
          if (getLocalVideoRef()) {
            _session.audioVideo.start();
          }
        });
      dispatch.setModal(Modais.none);
    }
  }

  function stopMeeting() {
    session.session.audioVideo.stop();
  }

  function toggleCam() {
    const getLocalVideoTile = session.session.audioVideo.getLocalVideoTile();

    // if (getLocalVideoTile.tileState.active) {
    if (getLocalVideoTile) {
      session.session.audioVideo.stopLocalVideoTile();
      session.session.audioVideo.removeLocalVideoTile();
      return;
    }
    console.log("startLocalVideoTile");
    session.session.audioVideo
      .chooseVideoInputDevice(devicesConfig.video_input)
      .then((devicePermission) => {
        if (getLocalVideoRef()) {
          session.session.audioVideo.startLocalVideoTile();
        }
      });
  }

  function toggleMic() {
    if (session.session.audioVideo.realtimeIsLocalAudioMuted()) {
      session.session.audioVideo.realtimeUnmuteLocalAudio();
      return;
    }
    session.session.audioVideo.realtimeMuteLocalAudio();
  }

  const observer = {
    videoTileDidUpdate: async function (data) {
      if (data.localTile) {
        state.session.session.audioVideo.bindVideoElement(
          data.tileId,
          getLocalVideoRef()
        );
        return;
      }
      state.session.session.audioVideo.bindVideoElement(
        data.tileId,
        getRemoteVideoRef()
      );
      if (data.boundAttendeeId) {
        const attendeeName = await GetAttendeeName(data.boundAttendeeId);
        dispatch.setAttendeeName(attendeeName);
      }
    },
    videoTileWasRemoved: (tileId) => {
      if (localTileId === tileId) {
        console.log(
          `You called removeLocalVideoTile. videoElement can be bound to another tile.`
        );
        dispatch.setLocalTileId(null);
      }
    },
    audioVideoDidStartConnecting: (reconnecting) => {
      console.log(
        `OBSERVER :: session connecting. reconnecting: ${reconnecting}`
      );
      const localTile = state.session.session.audioVideo.startLocalVideoTile();
      state.session.session.audioVideo.bindVideoElement(
        localTile.tileId,
        getLocalVideoRef()
      );
    },

    audioVideoDidStart: () => {
      console.log("OBSERVER :: session started");
    },
    audioVideoDidStop: () => {
      console.log("OBSERVER :: session stoped");
      dispatch.removeSession();
    },
  };

  useEffect(() => {
    const { meeting } = state;
    const haveMeetingAndAttendee =
      meeting.Meeting &&
      meeting.Meeting.MeetingId &&
      meeting.Attendee &&
      meeting.Attendee.AttendeeId;

    if (haveMeetingAndAttendee && state.modal === Modais.authenticate) {
      const config = new MeetingSessionConfiguration(
        meeting.Meeting,
        meeting.Attendee
      );
      const logger = new ConsoleLogger("ChimeMeetingLogs", LogLevel.INFO);
      const device_controller = new DefaultDeviceController(logger);
      const session = new DefaultMeetingSession(
        config,
        logger,
        device_controller
      );

      dispatch.setSession({
        logger: logger,
        session: session,
      });
    }
  }, [state, dispatch]);

  console.log("ESTADO :: state", state);

  return (
    <MuiThemeProvider theme={Theme}>
      <ThemeProvider theme={Theme}>
        <Modal open={modal === Modais.authenticate}>
          <Authenticate
            loading={loading}
            state={state.authData}
            setState={dispatch.setAuthData.bind(dispatch)}
            onJoin={onJoin}
            onCreate={onCreate}
          />
        </Modal>
        <Modal open={modal === Modais.devices}>
          <Devices
            session={session}
            meeting={meeting}
            attendeeName={state.authData.attendee_name}
            enterMeeting={enterMeeting}
            getDataVideQuality={getDataVideQuality}
          />
        </Modal>
        {modal === Modais.none && (
          <Meeting
            stopMeeting={stopMeeting}
            toggleCam={toggleCam}
            toggleMic={toggleMic}
            dispatch={dispatch}
            modais={Modais}
            ref={meetingRef}
            attendeeName={state.attendeeName}
          />
        )}
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
